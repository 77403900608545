@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

:root {
  --secondary-color: #062a8c;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9ecff5a; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:  #062a8c; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a39e9e; 
}
