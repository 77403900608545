.header {
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgb(248, 248, 248);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #021d68;
}

.header .navbar img {
    width: 250px;
    height: auto;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.nav-item .active {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.header .nav-item a:hover {
    cursor: pointer;
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}

.logo {
    width: 170px;
}


@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgb(255, 255, 255);
    }

    .logo {
        width: 200px;
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 80px;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.833);
        width: 100%;
        height: 95vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }
}