.business {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding-bottom: 4.5%;
    padding-top: 3%;
}

.box {
    padding-top: 2%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-bottom: 3%;
    border: 2px solid white;
    border-radius: 10px;
    margin: 2%;
}

.box h1 {
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(254, 254, 254);
    margin-left: 8%;
    margin-right: 8%;
}

.box p {
    color: white;
    padding-top: 5.5%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-bottom: 2%;
}

.box img {
    width: 40%;
    position: relative;
    left: 30%;
    padding-top: 2%;
    padding-bottom: 3%;
}

@media only screen and (max-width: 1045px) {
    .business {
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 512px) {
    .business {
        grid-template-columns: 100%;
    }
    .box {
        margin-top: 5%;
        margin-bottom: 5%;
    }
}